.node--type-page .gva-body-page .node--type-page .header-title {
  visibility: hidden; }

.node--type-team .content #page-main-content .main-content-inner {
  margin: auto 30px; }

.node--type-event .gva-body-page .content-main .event-full {
  margin: auto 30px; }
  .node--type-event .gva-body-page .content-main .event-full .post-thumbnail {
    display: inline-flex; }

.node--type-article .content-main-inner {
  margin: auto 30px; }

.path-webform .has-breadcrumb {
  margin: auto 30px; }

.node--type-page .gva-job-box {
  padding: 30px;
  border: 1px solid #dee2e6;
  background: #fff;
  margin-bottom: 30px;
  display: table;
  width: 100%; }
  .node--type-page .gva-job-box .logo-inner {
    width: 100px; }
    .node--type-page .gva-job-box .logo-inner img {
      height: 110px;
      Width: 100px; }
  .node--type-page .gva-job-box .content-inner .title {
    font-size: 11px;
    font-weight: 900; }

.gbb-row .bb-inner {
  padding-bottom: 0px; }

.column-content-inner {
  /* .text5 {
    margin: -40px auto;
    */ }
  .column-content-inner .text1, .column-content-inner .text2 {
    padding: 65px 0px;
    line-height: 1.5; }
  .column-content-inner .text3, .column-content-inner .text6, .column-content-inner .text5 {
    line-height: 1.5; }
  .column-content-inner .text4 {
    line-height: 1.5;
    padding: 15px 0px; }
  .column-content-inner .widget-content img {
    /*  width: 420px;
                height: 263px; */
    width: 100%;
    height: auto; }

.node--type-page .bb-inner #financersaformation {
  margin: -850px auto 0;
  height: 550px; }

.frontpage .gavias-blockbuilder-content .remove_padding {
  padding-top: 0px !important; }

/****/
@media (min-width: 768px) and (min-width: 992px) {
  .navigation .gva_menu .sub-menu li:hover > ul {
    -webkit-transform: rotateX(0);
    -moz-transform: rotateX(0);
    -ms-transform: rotateX(0);
    -o-transform: rotateX(0);
    transform: rotateX(0); } }
